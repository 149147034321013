<template>
    <div>
        <section id="page-title" class="page-title-parallax page-title-dark" style="background-image: url('/assets/images/about/parallax.jpg'); padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

			<div class="container clearfix">
				<h1>Job Openings</h1>
				<span>Join our Fabulous Team of Intelligent Individuals</span>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">Jobs</li>
				</ol>
			</div>

		</section><!-- #page-title end -->
        <!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<div class="container clearfix">
                    
					<div class="row col-mb-50">
                        <!-- sidebar -->
                        <div class="col-md-4">
                            <Sidebar/>
                        </div>
                        <!-- JD  -->
						<div class="col-md-8">
							<div>
                                <h3 style="width: 100%;padding: 0 0 0.75rem;border-bottom: 2px solid #1ABC9C;">Billing Specialist
                                <router-link to="/careers/billing-specialist/form" class="button button-3d m-0 button-primary" style="float:right">Apply Now</router-link></h3>
                                <h4>The Role</h4>
                                <p>We are looking for a competent <b>Billing Specialist</b> to undertake a variety of financial and non-financial tasks in order to help guarantee the partner’s revenues. You will handle the processing of incoming payments along with the issuing of necessary paperwork. The role will also perform many accounting tasks, organizational tasks and customer service to promote the organization’s financial health.</p>
                                <p>We know it can be tricky to apply for roles, wondering if the position is right for you and if you and your experience are suitable for the part. Many people won't apply for roles unless they feel that they tick every single box. At eFlex, we look for many different skills and abilities, and we're always looking for how new team members can add to eFlex and our culture. So if you don't think you quite meet all of the skills listed, we'd still love to hear from you!</p>

                                <div class="accordion accordion-bg">

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll be doing
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Assume the responsibility of receiving and sorting incoming payments with attention to credibility</li>
                                            <li><i class="icon-ok"></i>Generate invoices to be sent directly to a customer</li>
                                            <li><i class="icon-ok"></i>Create and update billing materials to be used internally and shared with customers</li>
                                            <li><i class="icon-ok"></i>Input payment history, upcoming payment information, or other financial data into the customer’s account</li>
                                            <li><i class="icon-ok"></i>Check the validity of the accounts and manage the status of accounts and balances and identify inconsistencies, including collaboration with customers, third-party payment providers, and other team members to resolve billing inconsistencies and errors</li>
                                            <li><i class="icon-ok"></i>Update accounts receivable database with new accounts and existing accounts</li>
                                            <li><i class="icon-ok"></i>Ensure all partners remain informed on their outstanding bills and deadlines</li>
                                            <li><i class="icon-ok"></i>Provide solutions to any relative problems of partners, including finding financial solutions for customers who may need payment assistance</li>
                                            <li><i class="icon-ok"></i>Write thorough reports on billing activity with clear and reliable data</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Qualifications and Requirements:
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Must have a college degree preferably graduate of and related course to accounting</li>
                                            <li><i class="icon-ok"></i>With more than two (2) years of work experience as a Billing Specialist in a BPO company</li>
                                            <li><i class="icon-ok"></i>With good customer service skill</li>
                                            <li><i class="icon-ok"></i>Experience with the use of Salesforce is a MUST.</li>
                                            <li><i class="icon-ok"></i>Self-awareness and a desire to improve continually</li>
                                            <li><i class="icon-ok"></i>Strong command of English and good communication skills (verbal and written)</li>
                                            <li><i class="icon-ok"></i>A team player</li>
                                            <li><i class="icon-ok"></i>Good probing skills: the ability to probe and determine what the problem is.</li>
                                            <li><i class="icon-ok"></i>Able to think logically/Critical thinking</li>
                                            <li><i class="icon-ok"></i>Adaptability: Ability to adapt quickly and learn software platforms</li>
                                            <li><i class="icon-ok"></i>Attention to detail</li>
                                            <li><i class="icon-ok"></i>Problem solving skills</li>
                                            <li><i class="icon-ok"></i>Effective listening</li>
                                            <li><i class="icon-ok"></i>Able to work well with minimal supervision</li>
                                            <li><i class="icon-ok"></i>Able to prioritize workload</li>
                                        </ul>
                                    </div>
                                    
                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll enjoy at eFlex
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-plus-sign"></i>We live our values as playmakers, obsessed with learning, care personally about our colleagues and partners, are radically open-minded, and take pride in everything we do.</li>
                                            <li><i class="icon-plus-sign"></i>We offer a Competitive Salary.</li>
                                            <li><i class="icon-plus-sign"></i>Work-from-home setup as we prepare to provide a safe environment for our employees. Our Future of Work plan is to move towards a hybrid work model (in-office & from home).</li>
                                            <li><i class="icon-plus-sign"></i>We offer comprehensive HMO and optical reimbursements.</li>
                                            <li><i class="icon-plus-sign"></i>Recharge and go on your next getaway or simply take time off for yourself through our flexible personal & sick days. We want our team to be happy and healthy :)</li>
                                            <li><i class="icon-plus-sign"></i>We support our employee's career growth and development by offering opportunities for promotion.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-title">
                                            About eFlexervices
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <p>eFlexervices is a 23-year-old BPO company providing exceptional quality and unwavering trust. Our partnership approach to the business allows us to match the right talent to every organization we support. We invest in people to optimize performance and maximize efficiency. We work hard to produce the highest possible results for our partners.</p>
                                        <p>Performance is the underlying foundation that drives eFlexervices. We deliver the metrics our partners expect through proper recruitment and heavy investment in the right people.</p>
                                        <p>Deep integration within our partners' organizations drives our team to take ownership of their work. This stakeholder mindset pushes higher performance, better quality, and longer retention.</p>
                                    </div>
                                    <router-link to="/careers/billing-specialist/form" class="button button-3d m-0 button-primary" style="float:left">Apply Now</router-link>
                                </div>
                                <div class="divider divider-sm"></div>
                            </div>
						</div>
                        
                    </div>

				</div>
			</div>
		</section><!-- #content end -->

    </div>
</template>

<script>
import Sidebar from '@/components/CareerNav.vue'
export default {
    data() {
        return {
            
        }
    },
    components: {
        Sidebar
    },
    mounted() {
		window.scrollTo(0, 0)
	}
}
</script>